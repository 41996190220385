a,
a:active,
a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-login {
  min-height: 100vh;
}

.o-login > .container {
  background: #ffffff;
  border-radius: 4px;
}

.o-signupForm {
  margin: 30px 0 0 0;
}

.o-loginForm {
  margin: 40px 0 0 0;
}

.o-loginForm__group {
  margin: auto;
}

.m-loginHeader {
  font-weight: bold;
  font-size: 16px;
  color: var(--gray);
}

.m-loginSubHeader {
  /* font-size: 24px; */
  font-weight: 600;
}

.--buttonBg {
  background-color: #ffffff;
  color: var(--black);
}

.MuiInput-underline:before {
  border-bottom: solid 1px #ffffff !important;
}

.MuiInput-underline:after {
  border-bottom: solid 1px #ffffff !important;
}

.closeBtn {
  position: fixed;
  top: 0;
  left: 0;
}

.closeBtn:hover {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .m-socialLoginConainer {
    border-left: 2px solid var(--black);
  }
}

.loginBtn {
  background: #f7931e !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}
