a,
a:active,
a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-layout {
  background-color: var(--lighterBg);
  min-height: 100vh;
}

.o-appLayout {
  background-color: var(--lightBg);
  min-height: 100vh;
}

.o-topNav {
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff !important;
  background-color: #0b0b10 !important;
}

.o-topNav a,
.o-topNav a:hover {
  text-decoration: underline;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff !important;
  background-color: #0b0b10 !important;
}

.o-sideNav {
  min-width: 230px;
  background-color: var(--black);
  padding: 1rem 0 !important;
  height: 100vh;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  color: #BFBFBF !important;
}

.o-sideNav div {
  color: #BFBFBF !important;
}

.a-logoContainer {
}

.a-logoutNav {
  margin-top: auto;
}

.m-headerMainHeader {
  font-size: 24px;
  font-weight: 800;
}

.m-headerSubHeader {
  /* font-size: 24px; */
  font-weight: 600;
}

.m-mainBodyContainer {
  padding-top: 4.5rem;
}

.o-bottomNav {
  position: fixed;
  bottom: 0;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
}

/* Controls bottom navigation label styling */

.o-sideNav .Mui-selected {
  color: #ffffff !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-right: #f7931e solid 8px !important;
}

.o-sideNav .Mui-selected div {
  color: #ffffff !important;
}

.tab .Mui-selected {
  color: var(--yellow) !important;
}

.a-navItem {
  color: var(--gray) !important;
}

.a-smallButton {
  font-size: 12px !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.a-backdrop {
  z-index: 11000 !important;
  color: var(--yellow);
}

footer {
  border-top: 1px solid #8a8a8a59;
}
