a,
a:active,
a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-dashboard {
  min-height: 100vh;
  padding-bottom: 100px;
  /* background-color: #FFFFFF; */
}

.m-dashboardHeader {
  font-size: 24px;
  font-weight: 800;
}

.m-dashboardSubHeader {
  /* font-size: 24px; */
  font-weight: 600;
}

.dashboardCard.m-card {
  background: #ffffff;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-x: auto;
}

/* .dashboardCard.m-card:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
} */

.m-card__title {
  font-weight: 700;
}

.m-card__description {
  font-size: 15px;
}

/* .--welcomeBg { */
/* background: url('/public/images/welcome.png') right no-repeat bottom; */
/* } */

.dashboardCard h6 {
  font-size: 0.75rem;
}

.apexcharts-toolbar {
  margin-top: -2.5rem;
}

.donutOne .apexcharts-legend {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.donutTwo .apexcharts-legend-series {
  padding: 0.4rem;
}

.apexcharts-legend-text {
  padding-left: 0.6rem;
}

.apexcharts-legend-marker {
  vertical-align: middle;
}

@media (min-width: 1048px) {
  .donutOne .apexcharts-legend {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 0 0.4rem 2rem;
    gap: 0;
    width: 55%;
  }
}

@media (max-width: 768px) {
  .donutOne .apexcharts-legend {
    grid-template-columns: repeat(1, 1fr);
  }
  .donutOne .apexcharts-legend-series {
    padding: 0 2rem;
  }
}

@media (min-width: 480px) {
  .donutTwo .apexcharts-legend.apx-legend-position-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .donutOne .apexcharts-legend {
    top: 0 !important;
  }

  .donutTwo .apexcharts-legend {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .donutOne .apexcharts-legend {
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    justify-content: center;
    padding: 0;
    gap: 0;
  }

  .donutOne .apexcharts-legend-series {
    padding: 0.5rem 0;
  }

  .chart {
    display: grid;
    justify-content: center;
  }
}
