a,
a:active,
a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-profile {
  min-height: 100vh;
}

.a-detailsTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #807373;
}

.a-detailsTitle__header {
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
}
