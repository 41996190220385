a, a:active, a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-invite {
  min-height: 70vh;
}

.o-inviteForm {
  margin: 100px 0 0 0;
}

.--inviteButtonBg {
  background-color: var(--darkYellow) !important;
  color: #FFFFFF !important;
}


.MuiInput-underline:before {
  border-bottom: solid 1px var(--black) !important;
}

.MuiInput-underline:after {
  border-bottom: solid 1px var(--black) !important;
}

.MuiDataGrid-row {
  /* margin: 50px 0; */
}