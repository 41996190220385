:root {
  --yellow: #fbad31;
  --lightYellow: #fef3e0;
  --darkYellow: #faac30;
  --black: #0b0b10;
  --gray: #4d4d4d;
  --lightBg: #e5e5e5;
  --lighterBg: #f7f7f7;
  --grey: #fafafb;
  --lightBlue: #bdebff;
  --lightBlue2: #f6fdff;
  --lightBlue3: #e0f6ff;
  --darkBlue: #80d0f3;
  --red: #f81515;
  --green: #00C16E;
}

.o-app {
  text-align: center;
}

.--grayText {
  color: var(--gray);
}

.--lightBlueBg {
  background-color: var(--lightBlue);
}

.--darkBlueBg {
  background-color: var(--darkBlue) !important;
}

.--greenBg {
  background-color: var(--green) !important;
}

.--redBg {
  background-color: var(--red) !important;
}

.--yellowBg {
  background-color: var(--darkYellow) !important;
}

.--yellowText {
  color: var(--darkYellow);
}

.--yellowLink,
.--yellowLink:hover {
  color: var(--darkYellow) !important;
  text-decoration: none;
}

.--lightBlueBorder {
  border: 1px solid var(--lightBlue);
}

.--darkBlueBorder {
  border: 1px solid var(--darkBlue) !important;
}

.--grayBorder {
  border: 1px solid var(--gray) !important;
}

.--yellowBorder {
  border: 1px solid var(--darkYellow);
}

.--underline {
  text-decoration: underline;
}

.--hoverPointer:hover {
  cursor: pointer;
}

.m-centerContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.m-navSideContainer {
  width: 230px;
  position: fixed;
}

.m-main {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .m-main {
    width: calc(100% - 230px) !important;
  }
}

/* @media only screen and (max-width: 768px) {
    .mobileBlock {
        width: 100%;
    }
} */

.Mui-focused {
  color: var(--black) !important;
}

.MuiPaper-elevation4 {
  box-shadow: 0px 2px 0px 0px rgb(0 0 0 / 14%) !important;
}

/* Remove Chrome's autofill bg color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
}

.--fixedBottom {
  position: fixed !important;
  bottom: 50px;
  right: 50px;
}

.centerItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendEmailBtn {
  background: #d9ffef !important;
  border: 0.5px solid #00c16e !important;
  color: #00c16e !important;
  font-size: 14px !important;
  font-weight: normal;
  line-height: 17px !important;
  border-radius: 5px !important;
}

.sendEmailBtn:hover {
  text-decoration: none;
  color: #00c16e !important;
  font-weight: normal;
}

.deactivateBtn {
  background: #ffebeb !important;
  border: 0.5px solid #f8444f !important;
  color: #f8444f !important;
  font-size: 14px !important;
  line-height: 17px !important;
  border-radius: 5px !important;
}

.viewBtn {
    background: transparent !important;
    border: 0.5px solid #F7931E !important;
    color: #F7931E !important;
    font-size: 14px !important;
    line-height: 17px !important;
    border-radius: 5px !important;
}

.createBtn {
    background: #F7931E !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
    line-height: 17px !important;
    border-radius: 5px !important;
}

.watchLiveBtn {
    background: transparent !important;
    color: #F7931E !important;
    border: #F7931E 1px solid !important;
    font-size: 14px !important;
    line-height: 17px !important;
    border-radius: 5px !important;
}
