a,
a:active,
a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-profile {
  min-height: 100vh;
}

.cardBgImg {
  position: relative;
  height: 300px;
  background: black;
  overflow: hidden;
}

.cardBgImg img.object-cover {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.cardBgImg section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}