a,
a:active,
a:hover {
  /* text-decoration: none !important; */
  color: var(--black) !important;
  font-weight: 800;
}

.o-profile {
  min-height: 100vh;
}

.cardText {
  font-size: 16px;
  line-height: 20px;
  color: #979797;
}

.banner {
  position: relative;
  height: 300px;
  background: #00000080;
  overflow: hidden;
}

.banner section {
  position: absolute;
  top: 0;
  left: 0;
}

.banner .bannerText {
  height: 48px;
  background: #0f0a0a11;
  opacity: 0.8;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

@media screen and(max-width: 639px) {
  .banner {
    height: 166px;
  }
}

@media screen and(max-width: 360px) {
  .banner {
    height: 86px;
  }
}

.content {
  color: #3e3e3e;
}

.topAuction {
  background: rgba(255, 217, 173, 0.2);
}

/* Carousel */
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  max-width: 600px;
}

.carousel-button {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.box-container {
  /* background-color: #ffffff; */
  /* display: flex; */
  border-radius: 8px;
  /* flex-direction: column; */
  align-items: center;
  /* margin: 20px; */
  width: 60%;
  height: 300px;
}

.carouselCard {
  /* background-color: #ffffff; */
  border-radius: 8px;
  border: 1px solid #eeeeee;
  width: 100%;
  height: 100%;
}

.carousel-container > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.carousel-content:last-child {
  padding: 0 !important;
}

@media screen and (max-width: 400px) {
  .carouselCard,
  .carousel-content:last-child {
    max-width: 200px !important;
  }
}
/* Carousel styles ends */

.addArtwork {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #f7931e;
  cursor: pointer;
  width: fit-content;
}

.fileUpload {
  background: #fcf8f2;
  border: 1px dashed #979797;
}

textarea {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  /* border-radius: 2px; */
  padding: 16px;
  min-height: 140px;
  resize: none !important;
}