.a-loginFormButton {
  text-transform: none !important;
}

.a-inputField {
  /* border: 0.630252px solid #c4c4c4; */
  background: #ffffff;
  border: 1px solid #c4c4c4;
  height: 46px !important;
}

.MuiInputBase-root {
  border-radius: 8px;
  height: 50px !important;
}
